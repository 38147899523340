
import Vue from "vue";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import {
  underWritingQuestionMapState,
  underWritingQuestionMapActions
} from "@/store/modules/underWritingQuestion";

interface IUnderWritingQuestionActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "underWritingQuestionActivitiesView",
  mixins: [ActivitiesMixin],

  data(): IUnderWritingQuestionActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  methods: {
    ...underWritingQuestionMapActions(["getUnderWritingQuestion"])
  },
  computed: {
    ...underWritingQuestionMapState(["editing", "makingApiRequest"]),
    queryOverride(): any {
      return {
        name__in: `
          ADD_UNDERWRITING_QUESTION_SUCCESS,
          ADD_UNDERWRITING_QUESTION_FAILURE,
          UPDATE_UNDERWRITING_QUESTION_SUCCESS,
          UPDATE_UNDERWRITING_QUESTION_FAILURE,
          DELETE_UNDERWRITING_QUESTION_SUCCESS,
          DELETE_UNDERWRITING_QUESTION_FAILURE,
          ADD_BULK_UNDERWRITING_QUESTION_SUCCESS,
          ADD_BULK_UNDERWRITING_QUESTION_FAILURE,
          DELETE_BULK_UNDERWRITING_QUESTION_SUCCESS,
          DELETE_BULK_UNDERWRITING_QUESTION_FAILURE`.replace(/\s/g, ""),
        activityType: "UnderWritingQuestionActivity"
      };
    }
  }
});
